import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取数据字典信息
export const getDataDict = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetDataDict?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取地图轨迹
export const getMapPath = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Vehicle/GetCarlocus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const reverseCoord = (params) => {
  return new Promise((resolve, reject) => {
    // axios.get(`https://apis.map.qq.com/ws/geocoder/v1/?key=CMSBZ-7MXLQ-KBS5T-GHK4M-A74VE-OQFUJ&location=${params}`,{
    //   output:"jsonp"
    // }).then((res)=>{
    //   console.log(res);
    // })
    axios.get(`${host}/api/EquipOverview/ReverseCoord?${_paramsToQueryString(params)}`).then(res => {
      if (res.code == 0) {
        resolve(res.data)
      } else {
        reject(res)
      }
    })
  })
}