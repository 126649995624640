<template>
  <div class="deviceSkim">
    <div
      class="topView"
      style="display: block; padding-bottom: 20px;min-width: 1128px;width:100%"
    >
      <div class="car_search" style="position: absolute" v-loading="loading">
        <!-- <el-input placeholder="请输入车辆刷新时间" v-model="time" class="input-with-select">
          <el-button slot="append">确定</el-button>
        </el-input> -->
        <el-input-number
          style="width:200px"
          v-model="time"
          @change="handleChange"
          :min="5"
          :max="600"
          placeholder="车辆刷新时间"
        ></el-input-number>
        <div
          style="background: #fff;margin-top: 20px;padding: 8px 20px;border-radius: 4px"
        >
          交通势态感知
          <el-switch
            v-model="isShowRoad"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeRoad"
          >
          </el-switch>
        </div>
      </div>
      <div v-loading="loading">
        <div id="container" style="width:auto;height:83.5vh;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetEquipLoacList } from "@/api/platform/deviceSkim/index";
import { reverseCoord } from "@/api/common/common";
import car from "../../../assets/static/car1.png";
import stopcar from "../../../assets/static/stopcar.png";
export default {
  data() {
    return {
      loading: false,
      marker: null,
      map: null, // 地图
      center: new TMap.LatLng(28.606169, 115.934794), // 中心点
      type: 0, // 判断是查询点 还是查询轨迹 1是点 2是轨迹
      site: {
        lng: "",
        lat: "",
      }, // 中心点
      points: [], // 车辆标注点
      interval: null,
      num: 0,
      oldList: [],
      labList: [], // 文本标注点
      time: 20,
      isShowRoad: false,
    };
  },
  mounted() {
    this.isShowRoad =
      localStorage.getItem("isShowRoad") == "true" ? true : false;
    this.initMap();
    this.getEquipDetail(1);
    this.$nextTick(() => {
      this.interval = setInterval(() => {
        this.getEquipDetail();
      }, this.time * 1000);
    });
  },
  created() {},
  computed: {},
  destroyed() {
    clearInterval(this.interval);
  },
  watch: {
    time(newval, oldval) {
      if (newval != "") {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.getEquipDetail();
        }, newval * 1000);
      }
    },
  },
  methods: {
    // ------------------------------------------------------------------------
    //  地图API
    initMap() {
      if (this.isShowRoad) {
        this.map = new TMap.Map("container", {
          center: this.center,
          mapStyleId: "style2",
          baseMap: [
            { type: "vector" }, //设置矢量底图
            {
              // traffic底图文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/docIndexMap#7
              type: "traffic",
              features: ["base", "flow"], // 路况图要素类型
              opacity: 0.2, // 路况线网图的透明度
            },
          ],
        });
      } else {
        this.map = new TMap.Map("container", {
          center: this.center,
        });
      }
    },
    // 获取标驻点
    getEquipDetail(num) {
      let params = {
        coordType: 2,
      };
      this.num++;
      if (!!num) {
        this.loading = true;
      }
      GetEquipLoacList(params)
        .then((res) => {
          this.loading = false;
          if (this.num === 1) {
            this.points = [];
            this.labList = [];
            res.data.forEach((item, index) => {
              if (item.Status == 3) {
                this.points.push({
                  id: index + 2,
                  styleId: "car-down",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    Status: item.Status,
                    IMEI: item.IMEI,
                    CarNumber: item.CarNumber,
                    Note: item.Note,
                  },
                });
              } else {
                this.points.push({
                  id: index + 2,
                  styleId: "car-down1",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    Status: item.Status,
                    IMEI: item.IMEI,
                    CarNumber: item.CarNumber,
                    Note: item.Note,
                  },
                });
              }
              this.labList.push({
                id: "label" + index,
                styleId: "label",
                position: new TMap.LatLng(item.Lat, item.Lng),
                content: !item.CarNumber
                  ? item.Note || "暂无车牌"
                  : item.CarNumber,
              });
            });
            this.oldList = [...res.data];
            this.setSite();
          } else {
            let arr = res.data;
            arr.forEach((item, index) => {
              if (
                this.oldList[index].Lat != arr[index].Lat &&
                this.oldList[index].Lng != arr[index].Lng
              ) {
                // this.marker.remove(this.marker.geometries[index].id);
                // this.label.remove(this.label.geometries[index].id);
                this.marker.updateGeometries([
                  // 修改车辆标注点
                  {
                    styleId: "car-down",
                    id: index + 2,
                    position: new TMap.LatLng(arr[index].Lat, arr[index].Lng),
                  },
                ]);
                this.label.updateGeometries([
                  // 修改文本标注点
                  {
                    styleId: "label",
                    id: "label" + index,
                    position: new TMap.LatLng(arr[index].Lat, arr[index].Lng),
                    content: !arr[index].CarNumber
                      ? arr[index].Note
                      : arr[index].CarNumber,
                  },
                ]);
                this.oldList = [...res.data];
              }
            });
          }
        })
        .catch((err) => {
          if (err.code === 403) {
            this.$router.push("/power/nopermission");
          }
        });
    },
    // 设置车辆最新的位置
    setSite() {
      this.marker = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          //样式定义
          "car-down": new TMap.MarkerStyle({
            //创建一个styleId为"car-down"的样式（styles的子属性名即为car-down）
            width: 40,
            height: 40,
            anchor: { x: 20, y: 20 },
            faceTo: "map",
            rotate: 0,
            src: car,
          }),
          "car-down1": new TMap.MarkerStyle({
            width: 40,
            height: 40,
            anchor: { x: 20, y: 20 },
            faceTo: "map",
            rotate: 0,
            src: stopcar,
          }),
        },
        geometries: [...this.points],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      this.marker.on("click", function(evt) {
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng,
        };

        if (!evt) {
          this.$message.warning("车辆数据有误!");
          return;
        }

        reverseCoord(params).then((res) => {
          //设置infoWindow
          infoWindow.open(); //打开信息窗
          infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
          //设置信息窗内容

          if (!evt) {
            infoWindow.setContent(`<div>
              <p style="text-align:left;font-size:15px;margin:0 10px;">暂无车辆信息</p>
              </div>`);
            return;
          }

          infoWindow.setContent(`<div>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${
              res.data.address
            }</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
              evt.geometry.position.lat
            }-${evt.geometry.position.lng}</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">时间：</span>${
              evt.geometry.properties.time
            }</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">设备号：</span>${
              evt.geometry.properties.IMEI
            }</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">车牌：</span>
              ${
                !evt.geometry.properties.CarNumber
                  ? "无"
                  : evt.geometry.properties.CarNumber
              }
            </p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">备注：</span>
              ${
                !evt.geometry.properties.Note
                  ? "无"
                  : evt.geometry.properties.Note
              }
            </p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">是否在运行：</span>
              ${evt.geometry.properties.Status == 3 ? "运行" : "停止"}
            </p>
            </div>`);
        });
      });
      // 文本标注点
      this.label = new TMap.MultiLabel({
        map: this.map,
        styles: {
          label: new TMap.LabelStyle({
            color: "#00aa00", //颜色属性
            size: 14, //文字大小属性
            offset: { x: -12, y: 26 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: "center", //文字水平对齐属性
            verticalAlignment: "middle", //文字垂直对齐属性
          }),
        },
        geometries: [...this.labList],
      });
    },
    handleChange(val) {
      if (val == undefined) {
        this.$message.warning("车辆刷新时间最少为5秒");
        this.$nextTick(() => {
          this.time = 5;
        });
        return;
      }
    },
    changeRoad(val) {
      localStorage.setItem("isShowRoad", val);
      this.map.destroy();
      this.initMap();
      this.setSite();
    },
  },
};
</script>
<style lang="scss">
.deviceSkim {
  .circle {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: green;
    border-radius: 50%;
  }
}
.car_search {
  .el-input-group__append {
    background-color: #409eff;
    color: #fff;
  }
}
</style>
<style lang="scss" scoped>
.deviceSkim {
  display: flex;
  flex-direction: column;
  .strengthen {
    margin-top: 20px;
    .imgs {
      margin-right: 10px;
    }
  }
  ul li {
    list-style: none;
  }
  .btn-wrap {
    z-index: 999;
    position: fixed;
    bottom: 10.5rem;
    margin-left: 3rem;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  }
  .btn {
    width: 75px;
    height: 30px;
    float: left;
    background-color: #fff;
    color: rgba(27, 142, 236, 1);
    font-size: 14px;
    border: 1px solid rgba(27, 142, 236, 1);
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
  }
  .btn:hover {
    background-color: rgba(27, 142, 236, 0.8);
    color: #fff;
  }
  .map {
    width: 100%;
    height: 1000px;
  }
  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
  }
  .outerBox {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    .top {
      display: flex;
      // justify-content: space-around;
      margin-bottom: 20px;
      .device {
        margin-right: 60px;
      }
    }
  }
  .innerBox {
    display: flex;
    .state {
      width: 20%;
    }
  }
}
.pageTitle {
  display: inline-block;
  margin-right: 16px;
}
.topView {
  position: relative;
  .car_search {
    position: absolute;
    z-index: 1001;
    top: 40px;
    left: 20px;
  }
}
.collapse_box {
  position: absolute;
  top: 110px;
  left: 40px;
  z-index: 1001;
  width: 300px;
  background-color: #fff;
  .electric_box {
    height: 300px;
    overflow-y: auto;
    padding: 0px 6px;
    .infinite-list-item {
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #eee;
    }
    .infinite-list-item:hover {
      background-color: #ddd;
    }
    .active {
      background-color: #ddd;
    }
    .load_item {
      text-align: center;
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .electric_box::-webkit-scrollbar {
    width: 4px;
  }
  .electric_box::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  .electric_box::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  .el-collapse-item {
    box-sizing: border-box;
  }
  ::v-deep .el-collapse-item__header {
    text-align: center;
    display: block;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    .el-collapse-item__arrow {
      float: right;
      margin-top: 16px;
      margin-right: 16px;
    }
  }
}
</style>
