import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'



// 获取设备详情
export const getEquipDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipOverview/GetEquipDatial?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取设备轨迹
export const getEquipTrack = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipOverview/GetEquipTrack?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 导出轨迹
export const ExportTrack = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipOverview/ExportTrack?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 获取起始装货点信息
export const GetMileageInfo = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipOverview/GetMileageInfo`, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 导出里程信息
export const ExportMileageInfo = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipOverview/ExportMileageInfo`, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 获取起始装货点信息列表
export const GetLoadingPointList = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/Temp/GetLoadingPointList`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 获取起始装货点信息列表
export const GetEquipOverviewLoadingPointList = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipOverview/GetLoadingPointList`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 获取所有设备最新位置
export const GetEquipLoacList = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipOverview/GetEquipLoacList`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 删除标记点
export const DelTrackInfo = (params) => {
  return new Promise((resolve, reject) => {
      axios.delete(`${host}/api/EquipOverview/DelTrackInfo?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}